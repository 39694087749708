import { configureStore } from '@reduxjs/toolkit';
import sidebar from './sidebar';
import toast from './toast';
import auth from './auth';
import modal from './modal';
import publications from './publications';
import courses from './courses';
import lectures from './lectures';
import tags from './tags';
import webinars from './webinars';
import referrals from './referrals';
import purchases from './purchases';
import audience from './audience';
import upload from './upload';
import consultations from './consultations';
import fulfillment from './fulfillment';

const store = configureStore({
	reducer: {
		sidebar,
		toast,
		auth,
		modal,
		publications,
		courses,
		lectures,
		tags,
		webinars,
		referrals,
		purchases,
		audience,
		upload,
		consultations,
		fulfillment
	},
});

export default store;
