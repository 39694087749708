import { createSlice } from '@reduxjs/toolkit';
import api from 'src/api/index';
import { showErrorToast } from './toast';

const initialState = {
	list: [],
	total: 0,
	loading: false,
};

const fulfillmentSlice = createSlice({
	name: 'fulfillment',
	initialState,
	reducers: {
		setFulfillmentList(state, action) {
			state.list = action.payload.list;
			state.total = action.payload.total;
		},
		setLoading(state, action) {
			state.loading = action.payload;
		}
	},
});

export const { setFulfillmentList, setLoading } = fulfillmentSlice.actions;

export const getFulfillmentList = (payload) => async (dispatch) => {
	dispatch(setLoading(true));
	try {
		const response = await api.fulfillmentList(payload);
		dispatch(setFulfillmentList(response));
	} catch (error) {
		console.error(error);
		dispatch(showErrorToast({
			description: 'Ошибка с получением списка клиентов',
		}));
	} finally {
		dispatch(setLoading(false));
	}
};

export const updateFilFullmentStatus = ({fulfillmentId, status}) => async (dispatch) => {
	dispatch(setLoading(true));
	try {
		await api.fulfillmentStatusUpdate({ fulfillmentId, status });
	} catch (error) {
		console.error(error);
		dispatch(showErrorToast({
			description: 'Ошибка с обновлением статуса клиента',
		}));
	} finally {
		dispatch(setLoading(false));
	}
 
};
 
export default fulfillmentSlice.reducer;