import { createSlice } from '@reduxjs/toolkit';
import api from 'src/api/index';
import { showErrorToast } from './toast';

const initialState = {
	list: [],
	total: 0,
	loading: false
};

const audienceSlice = createSlice({

	name: 'audience',
	initialState,
	reducers: {
		setAudienceList: (state, action) => {
			state.list = action.payload.list;
			state.total = action.payload.count;
		},
		appendAudienceList: (state, action) => {
			state.list = state.list.concat(action.payload.list);
			state.total = action.payload.count;
		},
		resetAudienceList: (state, action) => {
			state.list = [];
			state.total = 0;
		},
		updateAudienceItem: (state, action) => {
			const index = state.list.findIndex(item => item.id === action.payload.id);
			state.list.splice(index, 1, action.payload);
		},
		addAudienceItem: (state, action) => {
			state.list.push(action.payload);
		},
		removeAudienceItem: (state, action) => {
			state.list = state.list.filter((item) => action.payload !== item.id);
		},
		insertAudienceItem: (state, action) => {
			
			state.total = action.payload.count;

			const list = action.payload.list;

			const index = list.findIndex( item => item.id === action.payload.id );

			if ( index >= 0 ) {
				state.list.splice( index, 1, action.payload );
			} else {
				state.list.push( action.payload );
			}
		},
		setAudienceLoading: (state, action) => {
			state.loading = action.payload;
		}
	}

});

export const { setAudienceList, updateAudienceItem, 
	addAudienceItem, removeAudienceItem, 
	setAudienceLoading, resetAudienceList, appendAudienceList } = audienceSlice.actions;


export const nextPageAudienceList = (payload = {}) => async (dispatch, getState) => {
	try {

		const res = await api.audienceList(payload);
		dispatch(appendAudienceList(res));

	} catch (error) {

		dispatch(showErrorToast({
			description: 'Не удалось получить список клиентов'
		}));

		return Promise.reject(error);
		
	} 
};

export const fetchAudienceList = (payload = {}) => async (dispatch, getState) => {
	try {

		const res = await api.audienceList(payload);
		dispatch(setAudienceList(res));

	} catch (error) {

		dispatch(showErrorToast({
			description: 'Не удалось получить список клиентов'
		}));

		return Promise.reject(error);
		
	} 
};

export default audienceSlice.reducer;